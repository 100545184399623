<template>
    <div>
        <div class="title">薪资拆分条件设置</div>

        <el-divider></el-divider>

        <el-form ref="form" :rules="rules" :model="form">
            <el-form-item label="拆分规则名称：" prop="title">
                <el-input v-model="form.title"></el-input>
            </el-form-item>

            <!-- <el-form-item label="工资拆分区值：">
        <el-select v-model="form.deducte_rule">
          <el-option
            v-for="item in deducteRulesList"
            :key="item.id"
            label="item.label"
            value="item.id"
          ></el-option>
        </el-select>
      </el-form-item> -->

            <el-form-item label="自定义拆分区值：" prop="range">
                <div class="sp_input">
                    <el-input v-model="form.min"></el-input>
                    <div class="sp_input_suffix">~</div>
                    <el-input v-model="form.max"></el-input>
                </div>
            </el-form-item>

            <el-form-item label="拆分时间：" prop="type">
                <el-radio-group v-model="form.type">
                    <el-radio label="1">按月</el-radio>
                    <el-radio label="2">按半月（非全日制）</el-radio>
                </el-radio-group>
            </el-form-item>

            <!-- 适用部门 -->
            <el-form-item label="适用部门" prop="department_ids">
                <el-select
                    v-model="form.department_ids"
                    :multiple="true"
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in department_options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>

            <!-- 特殊人员 -->
            <el-form-item label="特殊人员">
                <el-cascader
                    v-model="employees"
                    :options="employeesOptions"
                    :props="{ multiple: true, emitPath: false }"
                    :show-all-levels="false"
                    collapse-tags
                ></el-cascader>
            </el-form-item>

            <!-- save -->
            <div
                class="custom_button no_select operation_button"
                @click="onSubmit"
            >
                保存
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: ["id"],
    data() {
        return {
            form: {
                id: "",
                title: "",
                min: "",
                max: "",
                type: "1",
                department_ids: "",
                staff_ids: "",
                range: "",
            },
            is_legal_holidays: true,

            department_options: [],
            deducteRulesList: [],
            employees: [],
            employeesOptions: [],

            rules: {
                title: [
                    {
                        required: true,
                        message: "请填写标题",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择拆分时间",
                        trigger: "change",
                    },
                ],
                range: [
                    {
                        required: true,
                        message: "请填写正确的拆分区值",
                        trigger: "blur",
                    },
                ],
                department_ids: [
                    {
                        required: true,
                        message: "请至少选择一个部门",
                        trigger: "change",
                    },
                ],
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    watch: {
        "form.min"(newVal) {
            if (
                newVal &&
                this.form.max &&
                Number(newVal) >= 0 &&
                Number(this.form.max) >= 0 &&
                Number(newVal) <= Number(this.form.max)
            ) {
                this.form.range = true;
                return;
            }
            this.form.range = "";
        },
        "form.max"(newVal) {
            if (
                newVal &&
                this.form.min &&
                Number(newVal) >= 0 &&
                Number(this.form.min) >= 0 &&
                Number(newVal) >= Number(this.form.min)
            ) {
                this.form.range = true;
                return;
            }
            this.form.range = "";
        },
    },
    created() {
        if (this.id) {
            // 获取记录
            this.getSalarySplitRule({ id: this.id })
                .then((res) => {
                    res = res.data.data;
                    console.log(res);
                    this.form = {
                        id: res.id,
                        title: res.title,
                        min: res.min,
                        max: res.max,
                        type: String(res.type),
                        department_ids: "",
                        staff_ids: "",
                        range: "",
                    };
                    if (res.department_ids)
                        this.form.department_ids = res.department_ids
                            .split(",")
                            .map((item) => Number(item));
                    if (res.staff_ids)
                        this.employees = res.staff_ids
                            .split(",")
                            .map((item) => Number(item));
                })
                .catch((e) => {
                    console.error(e);
                    this.$message.error(e.msg);
                });
        }

        this.getdepartmentList()
            .then((res) => {
                console.log(res);
                this.department_options = res.data.data;
            })
            .catch((e) => {
                console.error(e);
            });

        // 加载部门员工
        this.getdepartmentList()
            .then((res) => {
                console.log(res);
                this.employeesOptions = res.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                        children: item.staff.map((item) => {
                            return {
                                value: item.id,
                                label: item.real_name,
                            };
                        }),
                    };
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },
    methods: {
        ...mapActions("attendance", ["getdepartmentList"]),
        ...mapActions("pay", ["getSalarySplitRule", "editSalarySpiltRule"]),

        onDatesChange(e) {
            this.form.work_time_data = e;
        },

        onSpDatesChange(e) {
            this.form.special_date = e;
        },

        onSubmit() {
            // 装填
            console.log(this.form, this.employees);

            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.form.department_ids = this.form.department_ids.join(
                        ","
                    );
                    this.form.staff_ids = this.employees.join(",");

                    let loading = this.$loading();
                    this.editSalarySpiltRule(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                            this.$router.back();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
    width: 140px !important;
}

::v-deep .el-form-item__content {
    margin-left: 140px !important;
}
.el-radio.is-checked {
    ::v-deep .el-radio__inner {
        border-color: #4db9d5;
        background: #4db9d5;
    }

    ::v-deep .el-radio__label {
        color: #606266;
    }
}

.holiday {
    ::v-deep {
        .el-form-item__content {
            margin-left: 0 !important;
        }

        .el-checkbox__inner:hover {
            border-color: #4db9d5;
        }

        .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #4db9d5;
                    border-color: #4db9d5;
                }
            }

            &.is-focus {
                .el-checkbox__inner {
                    border-color: #4db9d5;
                }
            }
        }

        .el-checkbox__label {
            color: #495060;
        }
    }
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

// 表格内的表单大小
.cell {
    .el-input {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .el-select {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .sp_date_picker {
        width: 130px !important;
    }
}

.sp_input {
    position: relative;
    width: max-content;
    display: flex;

    .el-input {
        width: 200px;
    }

    .sp_input_suffix {
        font-size: 14px;
        color: #495060;
        width: 50px;
        text-align: center;
    }
}

.operation_button {
    width: 142px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    margin-top: 30px;
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
